import {
  ArrowRight,
  BookOpenText,
  DiscordLogo,
  List,
} from '@phosphor-icons/react';
import { Button } from '../ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from '../ui/drawer';
import { useMediaQuery } from 'usehooks-ts';
import { useEffect, useState } from 'react';
import { useLiveAPIContext } from '../../contexts/LiveAPIContext';
import { useOpenAiRealtime } from '../../hooks/use-openai-realtime';
import { useModel } from '../../contexts/ModelContext';

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pathname, setPathname] = useState('');
  const { disconnect: disconnectGemini } = useLiveAPIContext();
  const { disconnect: disconnectOpenAI } = useOpenAiRealtime();
  const { model } = useModel();

  const disconnect = model === 'openai' ? disconnectOpenAI : disconnectGemini;

  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);
  
  return (
    <nav
      className={`z-[100] bg-black border-b ${
        isOpen ? 'border-white/0' : 'border-white/15'
      } flex items-center justify-between h-[4rem] px-3 fixed top-0 left-0 w-full transition-all ease-in-out duration-200`}
    >
      <div>
        <Button className="bg-black/50 rounded-xl border border-white/20 cursor-pointer h-10/12 aspect-square flex items-center justify-center h-[3rem]">
          <a href="https://focus.omega.inc?ref=v2v">
            <svg
              width="24"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8906 1.24879C7.69972 2.34086 5.71522 4.67548 5.71325 8.51561C5.71184 11.7094 7.07787 13.9465 9.77956 15.1738C11.6029 16.0023 11.2519 16.4497 8.77831 16.4497C6.54688 16.4497 5.5625 16.1431 5.5625 15.4481C5.5625 15.2197 5.43594 15.0329 5.28125 15.0329C5.12066 15.0329 5 15.883 5 17.0165V17.9714C5 18.3315 5 18.5115 5.07007 18.649C5.1317 18.77 5.23004 18.8683 5.35101 18.9299C5.48852 19 5.66854 19 6.02857 19H8.69506H11.2516C11.6461 19 11.8433 19 11.9877 18.9208C12.1146 18.8512 12.2142 18.7409 12.2706 18.6077C12.3348 18.456 12.3148 18.2598 12.2749 17.8674L12.1972 17.1029C12.0192 15.3523 11.9202 15.1332 10.915 14.2662C9.58831 13.1222 8.85369 11.3135 8.83091 9.1356C8.77353 3.61514 12.7777 0.408336 16.7098 2.82513C19.0794 4.28161 19.9836 9.32319 18.4423 12.4849C17.9842 13.4249 17.341 14.2483 16.8252 14.5552C16.1569 14.953 15.9682 15.2636 15.9657 15.97C15.964 16.4676 15.8841 17.3531 15.7882 17.9374C15.7408 18.2262 15.7171 18.3705 15.7412 18.4871C15.7855 18.7017 15.936 18.8789 16.1405 18.9574C16.2517 19 16.398 19 16.6906 19H19.3069H21.9714C22.3315 19 22.5115 19 22.649 18.9299C22.77 18.8683 22.8683 18.77 22.9299 18.649C23 18.5115 23 18.3315 23 17.9714V17.0165C23 15.883 22.8793 15.0329 22.7188 15.0329C22.5641 15.0329 22.4375 15.2197 22.4375 15.4481C22.4375 16.1431 21.4531 16.4497 19.2217 16.4497C16.6044 16.4497 16.4663 16.0723 18.6612 14.9168C19.5226 14.4634 20.5323 13.6895 20.905 13.1973C22.327 11.3189 22.804 8.53884 22.1068 6.19402C21.0532 2.65115 15.9634 0.187314 11.8906 1.24879Z"
                fill="white"
                fillOpacity="0.95"
              />
            </svg>
          </a>
        </Button>
      </div>
      <div className="flex items-center justify-end gap-2">
        <Button className="bg-black/50 rounded-xl border border-white/20 cursor-pointer h-10/12 aspect-square flex items-center justify-center h-[3rem]">
          <a
            href="https://now.sn21.ai/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenText size={32} />
          </a>
        </Button>

        <Button className="bg-black/50 rounded-xl border border-white/20 cursor-pointer aspect-square flex items-center justify-center h-[3rem]">
          <a
            href="https://discord.gg/ccrvTpetKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DiscordLogo size={28} />
          </a>
        </Button>
        <Drawer direction="top" open={isOpen} onOpenChange={setIsOpen}>
          <DrawerTrigger asChild>
            <Button
              onClick={() => setIsOpen((prev) => !prev)}
              className="bg-black/50 rounded-xl border border-white/20 p-2 cursor-pointer h-10/12 aspect-square flex items-center justify-center h-[3rem]"
            >
              <List size={28} />
            </Button>
          </DrawerTrigger>
          <DrawerContent className="text-white inset-0 h-fit mt-[4rem] bg-black !border-b border-white/15 p-3 pb-5 gap-2">
            {pathname !== '/chat' && (
              <Button className="w-full h-auto py-3 rounded-lg border transition-all text-center text-sm bg-black/30 border-white/15">
                <a href="/chat" className="flex items-center gap-1">
                  Muse Chat
                </a>
              </Button>
            )}
            {pathname !== '/dashboard' && (
              <Button
                onClick={disconnect}
                className="w-full h-auto py-3 rounded-lg border transition-all text-center text-sm bg-black/30 border-white/15"
              >
                <a href="/dashboard" className="flex items-center gap-1">
                  Model Evaluations
                </a>
              </Button>
            )}
            <Button className="w-full h-auto py-3 rounded-lg border transition-all text-center text-sm bg-black/30 border-white/15">
              Earn with Focus
              <ArrowRight />
            </Button>
            {/* </div> */}
          </DrawerContent>
        </Drawer>
      </div>
    </nav>
  );
};

const Navbar = () => {
  const isPhone = useMediaQuery('(max-width: 480px)');
  const [pathname, setPathname] = useState('');
  const { disconnect: disconnectGemini } = useLiveAPIContext(); 
  const { disconnect: disconnectOpenAI } = useOpenAiRealtime();
  const { model } = useModel();

  const disconnect = model === 'openai' ? disconnectOpenAI : disconnectGemini;

  useEffect(() => {
    setPathname(window.location.pathname);
  }, []);

  if (isPhone) return <MobileNav />;
  return (
    <div className="z-50 h-[5rem] bg-black/40 border-b border-white/20 flex justify-center items-center fixed top-0 left-0 w-full backdrop-blur-md">
      <div className="py-3 px-20 mx-auto max-w-5xl w-full flex items-center justify-between">
        <div className="flex items-center gap-2">
          <a href="https://focus.omega.inc?ref=v2v">
            <svg
              width="28"
              height="20"
              viewBox="0 0 28 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8906 1.24879C7.69972 2.34086 5.71522 4.67548 5.71325 8.51561C5.71184 11.7094 7.07787 13.9465 9.77956 15.1738C11.6029 16.0023 11.2519 16.4497 8.77831 16.4497C6.54688 16.4497 5.5625 16.1431 5.5625 15.4481C5.5625 15.2197 5.43594 15.0329 5.28125 15.0329C5.12066 15.0329 5 15.883 5 17.0165V17.9714C5 18.3315 5 18.5115 5.07007 18.649C5.1317 18.77 5.23004 18.8683 5.35101 18.9299C5.48852 19 5.66854 19 6.02857 19H8.69506H11.2516C11.6461 19 11.8433 19 11.9877 18.9208C12.1146 18.8512 12.2142 18.7409 12.2706 18.6077C12.3348 18.456 12.3148 18.2598 12.2749 17.8674L12.1972 17.1029C12.0192 15.3523 11.9202 15.1332 10.915 14.2662C9.58831 13.1222 8.85369 11.3135 8.83091 9.1356C8.77353 3.61514 12.7777 0.408336 16.7098 2.82513C19.0794 4.28161 19.9836 9.32319 18.4423 12.4849C17.9842 13.4249 17.341 14.2483 16.8252 14.5552C16.1569 14.953 15.9682 15.2636 15.9657 15.97C15.964 16.4676 15.8841 17.3531 15.7882 17.9374C15.7408 18.2262 15.7171 18.3705 15.7412 18.4871C15.7855 18.7017 15.936 18.8789 16.1405 18.9574C16.2517 19 16.398 19 16.6906 19H19.3069H21.9714C22.3315 19 22.5115 19 22.649 18.9299C22.77 18.8683 22.8683 18.77 22.9299 18.649C23 18.5115 23 18.3315 23 17.9714V17.0165C23 15.883 22.8793 15.0329 22.7188 15.0329C22.5641 15.0329 22.4375 15.2197 22.4375 15.4481C22.4375 16.1431 21.4531 16.4497 19.2217 16.4497C16.6044 16.4497 16.4663 16.0723 18.6612 14.9168C19.5226 14.4634 20.5323 13.6895 20.905 13.1973C22.327 11.3189 22.804 8.53884 22.1068 6.19402C21.0532 2.65115 15.9634 0.187314 11.8906 1.24879Z"
                fill="white"
                fillOpacity="0.95"
              />
            </svg>
          </a>
          <div className="flex items-center gap-1 bg-black/50 rounded-lg px-3 py-1.5 border border-white/20 font-semibold">
            Muse — <i>Any2Any</i>{' '}
            <span className="text-xs text-white/70">(beta)</span>
          </div>
        </div>
        <div className="flex items-center gap-3 content-api-key">
          {pathname !== '/chat' && (
            <Button className="bg-black/50 rounded-lg px-3 py-1.5 border focus:outline-none hover:border-white/70 border-white/15 transition-all ease-in-out duration-200">
              <a href="/chat" className="flex items-center gap-1">
                Muse Chat
              </a>
            </Button>
          )}
          {pathname !== '/dashboard' && (
            <Button
              onClick={disconnect}
              className="bg-black/50 rounded-lg px-3 py-1.5 border focus:outline-none hover:border-white/70 border-white/15 transition-all ease-in-out duration-200"
            >
              <a href="/dashboard" className="flex items-center gap-1">
                Model Evaluations
              </a>
            </Button>
          )}
          <Button className="bg-black/50 rounded-lg px-3 py-1.5 border focus:outline-none hover:border-white/70 border-white/15 transition-all ease-in-out duration-200">
            <a
              href="https://focus.omega.inc?ref=v2v"
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-1"
            >
              Earn with Focus
              <ArrowRight />
            </a>
          </Button>

          <Button className="bg-black/50 aspect-square rounded-lg border cursor-pointer z-10 hover:border-white/70 border-white/15 transition-all ease-in-out duration-200 p-0">
            <a
              href="https://now.sn21.ai/"
              target="_blank"
              rel="noopener noreferrer"
              className="aspect-square"
            >
              <BookOpenText size={32} />
            </a>
          </Button>

          <Button className="bg-black/50 aspect-square rounded-lg border cursor-pointer z-10 hover:border-white/70 border-white/15 transition-all ease-in-out duration-200 p-0">
            <a
              href="https://discord.gg/ccrvTpetKY"
              target="_blank"
              rel="noopener noreferrer"
              className="aspect-square"
            >
              <DiscordLogo size={28} />
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
