'use client';

import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '../components/ui/chart';
import { useMediaQuery } from 'usehooks-ts';

interface ModelData {
  modelType: string;
  modelName: string;
  score: number;
  task: string;
  competition_id: string;
}

interface MetricDataPoint {
  date: string;
  models: ModelData[];
}

interface StackedAreaChartProps {
  data: MetricDataPoint[];
  title: string;
  description: string;
  colors: string[];
}

function StackedAreaChart({
  data,
  title,
  description,
  colors,
}: StackedAreaChartProps) {
  const isPhone = useMediaQuery('(max-width: 480px)');

  // Transform data to group by modelType and include model names
  const transformedData = data.map((point) => {
    const dateObj = new Date(point.date);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
    }).format(dateObj);

    // Create an object to store scores and names by modelType
    const modelTypeScores: { [key: string]: number } = {};
    const modelDetails: { [key: string]: { name: string; score: number } } = {};

    point.models.forEach((model) => {
      modelTypeScores[model.modelType] = model.score;
      modelDetails[model.modelType] = {
        name: model.modelName,
        score: model.score,
      };
    });

    return {
      date: formattedDate,
      ...modelTypeScores,
      modelDetails, // Store full details for tooltip
    };
  });

  // Get unique model types
  const modelTypes = Array.from(
    new Set(
      data.flatMap((point) => point.models.map((model) => model.modelType))
    )
  );

  const chartConfig: ChartConfig = modelTypes.reduce((acc, type, index) => {
    acc[type] = {
      label: type,
      color: colors[index % colors.length],
    };
    return acc;
  }, {} as ChartConfig);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="rounded-lg border border-border bg-popover p-3 shadow-sm bg-black border-white/20">
            <div className="flex gap-2 items-center mb-2">
              <span className="text-sm font-medium text-muted-foreground">
                Date
              </span>
              <span className="font-bold text-popover-foreground">{label}</span>
            </div>
            {payload.map((entry: any, index: number) => {
              const modelDetails = entry.payload.modelDetails?.[entry.dataKey];
              if (!modelDetails) return null;

              return (
                <div key={index} className="flex items-center gap-2">
                  <span
                    className="text-sm font-medium"
                    style={{ color: entry.color }}
                  >
                    {modelDetails.name}
                  </span>
                  <span className="font-bold text-popover-foreground">
                    {modelDetails.score.toFixed(2)}
                  </span>
                </div>
              );
            })}
        </div>
      );
    }
    return null;
  };

  return (
    <Card className="w-full border-none">
      <CardHeader>
        <CardTitle className='text-2xl'>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <AreaChart
            data={transformedData}
            margin={{ top: 30, right: 10, bottom: 20 }}
            height={isPhone ? 300 : 400}
          >
            <defs>
              {modelTypes.map((type, index) => (
                <linearGradient
                  key={`gradient-${type}`}
                  id={`gradient-${type}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={colors[index % colors.length]}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={colors[index % colors.length]}
                    stopOpacity={0.1}
                  />
                </linearGradient>
              ))}
            </defs>
            <CartesianGrid vertical={false} className="opacity-20" />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
            />
            <YAxis tickLine={false} axisLine={false} tickMargin={8} />
            <ChartTooltip content={<CustomTooltip />} />
            {modelTypes.map((type, index) => (
              <Area
                key={type}
                type="monotone"
                dataKey={type}
                name={type}
                stackId="1"
                fill={`url(#gradient-${type})`}
                stroke={colors[index % colors.length]}
                fillOpacity={0.4}
                dot={{
                  r: 4,
                  fill: colors[index % colors.length],
                  strokeWidth: 2,
                  stroke: colors[index % colors.length],
                }}
              />
            ))}
            <ChartLegend
              align={isPhone ? 'center' : 'right'}
              verticalAlign={isPhone ? 'bottom' : 'top'}
              content={
                <ChartLegendContent
                  className={
                    isPhone ? 'flex-wrap justify-center' : 'justify-end'
                  }
                />
              }
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}

export default StackedAreaChart;
