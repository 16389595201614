import { Microphone, MicrophoneSlash } from '@phosphor-icons/react';
import { useModel } from '../../contexts/ModelContext';
import { Button } from '../ui/button';
import CallOptions from './CallOptions';
import ModelOptions from './ModelOptions';
import { useEffect, useState } from 'react';
import VideoFeed from '../VideoFeed';
import { useScreenCapture } from '../../hooks/use-screen-capture';
import { useWebcam } from '../../hooks/use-webcam';
import HiddenVideoContext from './HiddenVIdeoContext';
import { AudioRecorder } from '../../gemini/audio-recorder';
import { useOpenAiRealtime } from '../../hooks/use-openai-realtime';

const DesktopControlTray = ({
  isConnected,
  handleDisconnect,
  handleConnect,
  client
}: {
  isConnected: boolean;
  handleDisconnect: () => void;
  handleConnect: () => Promise<void>;
  client: any
}) => {
  const { model } = useModel();

  const {
    muted: openAIMuted,
    setMuted: setOpenAIMuted,
  } = useOpenAiRealtime();

  const {
    isStreaming: isWebcamStreaming,
    stream: webcamStream,
    stop: stopWebcam,
  } = useWebcam();
  const {
    isStreaming: isScreenCapturing,
    stream: screenStream,
    stop: stopScreenCapture,
  } = useScreenCapture();

  const [audioRecorder] = useState(() => new AudioRecorder());

  const disconnect = () => {
    stopWebcam();
    stopScreenCapture();
    handleDisconnect();
  }

  const [geminiMuted, setGeminiMuted] = useState(false);
  const muted = model === 'openai' ? openAIMuted : geminiMuted;
  const setMuted = model === 'openai' ? setOpenAIMuted : setGeminiMuted;

  const baseVideoClasses =
    'fixed aspect-[11/7] bg-black rounded-lg overflow-hidden';

  const webcamClasses = `${baseVideoClasses} z-[100] ${
    isScreenCapturing
      ? 'bottom-[6rem] right-[40px] w-24'
      : 'bottom-[6rem] right-[40px] w-64'
  }`;

  const screenClasses = `${baseVideoClasses} z-[50] ${
    isWebcamStreaming
      ? 'bottom-[7rem] right-[56px] w-64'
      : 'bottom-[6rem] right-[40px] w-64'
  }`;

  useEffect(() => {
    if (model === 'gemini') {
      const onData = (base64: string) => {
        client.sendRealtimeInput([
          {
            mimeType: 'audio/pcm;rate=16000',
            data: base64,
          },
        ]);
      };
      if (isConnected && !muted && audioRecorder) {
        audioRecorder.on('data', onData).start();
      } else {
        audioRecorder.stop();
      }
      return () => {
        audioRecorder.off('data', onData);
      };
    }
  }, [isConnected, client, muted, audioRecorder, model]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        (event.key === 'm' || event.key === 'M') &&
        !(event.target instanceof HTMLInputElement) &&
        !(event.target instanceof HTMLTextAreaElement)
      ) {
        setMuted(!muted);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <>
      {webcamStream && (
        <VideoFeed stream={webcamStream} className={webcamClasses} />
      )}
      {screenStream && (
        <VideoFeed stream={screenStream} className={screenClasses} />
      )}

      {model === 'gemini' && (
        <HiddenVideoContext
          client={client}
          isConnected={isConnected}
          webcamStream={webcamStream}
          screenStream={screenStream}
        />
      )}

      <div className="mb-[40px] w-full">
        {isConnected ? (
          <div className="w-full px-[40px] grid grid-cols-3 items-center">
            <div className="flex">
              <ModelOptions isConnected={isConnected} />
            </div>

            <CallOptions handleDisconnect={disconnect} />

            <div className="flex justify-end items-center h-10">
              <Button
                onClick={() => setMuted(!muted)}
                className={`border px-2 items-center rounded-lg ${
                  muted
                    ? 'border-disconnect/50 hover:disconnect/70'
                    : 'border-white/15 hover:border-white/70'
                }`}
              >
                <span className="py-0.5 px-1 bg-white/15 rounded aspect-square text-xs">
                  M
                </span>
                {muted ? (
                  <MicrophoneSlash size={28} className="text-disconnect" />
                ) : (
                  <Microphone size={28} className="text-white" />
                )}
              </Button>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col justify-center items-center gap-3">
            <ModelOptions isConnected={isConnected} />
            <div className="border border-white/30 rounded-full p-2 group hover:border-white/70 transition-all ease-in-out duration-200 z-10">
              <Button
                onClick={handleConnect}
                className="bg-white text-black rounded-full font-bold group-hover:scale-[1.01] transition-all ease-in-out duration-200"
              >
                Start a Call with your OMEGA Muse
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DesktopControlTray;
