import { create } from 'zustand';
import { AudioRecorder } from '../gemini/audio-recorder';

interface GeminiState {
  audioRecorder: AudioRecorder;
  isRecording: boolean;
  setIsRecording: (isRecording: boolean) => void;
  stream: MediaStream | null;
  setStream: (stream: MediaStream | null) => void;
}

export const useGeminiStore = create<GeminiState>((set) => ({
  audioRecorder: new AudioRecorder(16000),
  isRecording: false,
  setIsRecording: (isRecording) => set({ isRecording }),
  stream: null,
  setStream: (stream) => set({ stream }),
}));
