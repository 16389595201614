import { useMediaQuery } from 'usehooks-ts';
import DesktopControlTray from './DesktopTray';
import PhoneControlTray from './PhoneTray';

const Tray = ({
  isConnected,
  handleConnect,
  handleDisconnect,
  client,
}: {
  isConnected: boolean;
  handleConnect: () => Promise<void>;
  handleDisconnect: () => void;
  client: any;
}) => {
  const isPhone = useMediaQuery('(max-width: 480px)');
  return isPhone ? (
    <PhoneControlTray
      isConnected={isConnected}
      handleConnect={handleConnect}
      handleDisconnect={handleDisconnect}
      client={client}
    />
  ) : (
    <DesktopControlTray
      isConnected={isConnected}
      handleConnect={handleConnect}
      handleDisconnect={handleDisconnect}
      client={client}
    />
  );
};

export default Tray;
