import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { LiveAPIProvider } from './contexts/LiveAPIContext';
import { ModelProvider } from './contexts/ModelContext';
import EvalDashboard from './pages/EvalDashboard';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConsolePage } from './pages/ConsolePage';

const RELAY_SERVER_URL: string = process.env.REACT_APP_RELAY_SERVER_URL || '';

const queryClient = new QueryClient();

function App() {
  return (
    <div data-component="App">
      <ModelProvider initialModel="openai">
        <LiveAPIProvider url={RELAY_SERVER_URL + "/multimodal"}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Navigate to="/chat" replace />} />
                <Route path="/chat" element={<ConsolePage />} />
                <Route path="/dashboard" element={<EvalDashboard />} />
                <Route path="*" element={<Navigate to="/chat" replace />} />
              </Routes>
            </BrowserRouter>
          </QueryClientProvider>
        </LiveAPIProvider>
      </ModelProvider>
    </div>
  );
}

export default App;
