import {
  MonitorArrowUp,
  PhoneDisconnect,
  VideoCamera,
} from '@phosphor-icons/react';
import { memo } from 'react';
import { Button } from '../ui/button';
import { cn } from '../../utils/cn';
import { useModel } from '../../contexts/ModelContext';
import { useScreenCapture } from '../../hooks/use-screen-capture';
import { useWebcam } from '../../hooks/use-webcam';

interface IconButtonProps {
  isOn: boolean;
  onClick: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const IconButton = memo(
  ({ isOn, onClick, disabled = false, children }: IconButtonProps) => {
    return (
      <Button
        disabled={disabled}
        className={cn(
          'relative aspect-square p-4',
          'bg-black rounded-lg',
          'transition-all duration-200',
          isOn
            ? [
                'before:absolute before:inset-0',
                'before:rounded-lg before:p-[1px]',
                'before:bg-gradient-to-r before:from-[#FBE7FF] before:to-[#7160E9]',
                'before:content-[""]',
                'hover:before:opacity-80',
              ]
            : ['border border-white/15', 'hover:border-white/70']
        )}
        onClick={onClick}
      >
        <span
          className={cn(
            'absolute inset-[1px] rounded-[7px] bg-black z-0 flex justify-center items-center'
          )}
        >
          {children}
        </span>
      </Button>
    );
  }
);

const CallOptions = ({
  handleDisconnect,
}: {
  handleDisconnect: () => void;
}) => {
  const { model } = useModel();

  const {
    isStreaming: isScreenCapturing,
    start: startScreenCapture,
    stop: stopScreenCapture,
  } = useScreenCapture();

  const {
    isStreaming: isWebcamStreaming,
    start: startWebcam,
    stop: stopWebcam,
  } = useWebcam();

  const handleScreenCapture = async () => {
    if (isScreenCapturing) {
      stopScreenCapture();
    } else {
      await startScreenCapture();
    }
  };

  const handleWebcamCapture = async () => {
    if (isWebcamStreaming) {
      stopWebcam();
    } else {
      await startWebcam();
    }
  };

  return (
    <div className="flex justify-center items-center gap-3">
      <IconButton
        isOn={isWebcamStreaming}
        onClick={handleWebcamCapture}
        disabled={model === 'openai'}
      >
        <VideoCamera size={28} />
      </IconButton>
      <Button
        onClick={handleDisconnect}
        className="w-18 aspect-[4/3] rounded-full border border-white/0 bg-disconnect focus:outline-none hover:opacity-90 h-[3rem] hover:border-white/70 transition-all ease-out duration-200 z-10"
      >
        <PhoneDisconnect weight="fill" size={36} className="text-white w-10" />
      </Button>
      <IconButton
        isOn={isScreenCapturing}
        onClick={handleScreenCapture}
        disabled={model === 'openai'}
      >
        <MonitorArrowUp size={28} />
      </IconButton>
    </div>
  );
};

export default CallOptions;
