import { useEffect, useState } from 'react';
import Spline from '@splinetool/react-spline';
import Navbar from '../components/Navbar';
import { useOpenAiRealtime } from '../hooks/use-openai-realtime';
import { useGeminiLive } from '../hooks/use-gemini-live';
import './ConsolePage.scss';
import { useModel } from '../contexts/ModelContext';
import Tray from '../components/Tray';
import Transcript from '../components/Transcript';

export function ConsolePage() {
  const { model, openAiVoice, geminiVoice } = useModel();
  const [splineKey, setSplineKey] = useState(0);

  useEffect(() => {
    setSplineKey((prev) => prev + 1);
  }, [model]);

  // OpenAI specific state and handlers
  const {
    isConnected: isOpenAiConnected,
    connect: connectOpenAI,
    disconnect: disconnectOpenAI,
    items,
    onSplineLoad: onSplineLoadOpenAI,
    client: openAiClient,
  } = useOpenAiRealtime();

  // Gemini specific state and handlers
  const {
    isConnected: isGeminiConnected,
    connect: connectGemini,
    disconnect: disconnectGemini,
    onSplineLoad: onSplineLoadGemini,
    client: geminiClient,
  } = useGeminiLive();

  const currentVoice = model === 'openai' ? openAiVoice : geminiVoice;
  const client = model === 'openai' ? openAiClient : geminiClient;
  const isConnected =
    model === 'openai' ? isOpenAiConnected : isGeminiConnected;

  const handleConnect = async () => {
    if (model === 'openai') {
      await connectOpenAI(currentVoice);
    } else {
      await connectGemini();
    }
  };

  const handleDisconnect = async () => {
    if (model === 'openai') {
      await disconnectOpenAI();
    } else {
      await disconnectGemini();
    }
  };

  // Auto-scroll conversation logs for OpenAI
  useEffect(() => {
    if (!items?.length) return;

    const conversationEls = document.querySelectorAll(
      '[data-conversation-content]'
    );
    conversationEls.forEach((el) => {
      (el as HTMLDivElement).scrollTop = (el as HTMLDivElement).scrollHeight;
    });
  }, [items]);

  return (
    <div data-component="ConsolePage">
      <Navbar />
      <div className="content-main md:p-[40px] flex flex-col">
        <div className="fixed top-0 left-0 w-screen h-screen">
          <Spline
            key={splineKey}
            scene="https://prod.spline.design/rZW2hRuaa6hLD8Qy/scene.splinecode"
            className="relative !w-screen !h-screen"
            onLoad={
              model === 'openai' ? onSplineLoadOpenAI : onSplineLoadGemini
            }
          />
        </div>
        {model === 'openai' && <Transcript items={items} />}
        <div className="z-10 mt-auto pointer-events-auto content-actions absolute bottom-0 left-0 w-full">
          <Tray
            isConnected={isConnected}
            handleConnect={handleConnect}
            handleDisconnect={handleDisconnect}
            client={client}
          />
        </div>
      </div>
    </div>
  );
}
