import { create } from 'zustand';
import { WavRecorder } from '../lib/wavtools/index.js';

interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

interface OpenAIState {
  muted: boolean;
  setMuted: (muted: boolean) => void;
  isConnected: boolean;
  setIsConnected: (isConnected: boolean) => void;
  isRecording: boolean;
  setIsRecording: (isRecording: boolean) => void;
  wavRecorder: WavRecorder;
}

export const useOpenAIStore = create<OpenAIState>((set) => ({
  muted: false,
  setMuted: (muted) => set({ muted }),
  isConnected: false,
  setIsConnected: (isConnected) => set({ isConnected }),
  isRecording: false,
  setIsRecording: (isRecording) => set({ isRecording }),
  wavRecorder: new WavRecorder({ sampleRate: 24000 }),
}));
