import React, { useState, useEffect, memo } from 'react';
import {
  HeadCircuit,
  Microphone,
  MicrophoneSlash,
  MonitorArrowUp,
  Phone,
  PhoneDisconnect,
  VideoCamera,
} from '@phosphor-icons/react';
import { Drawer, DrawerContent, DrawerTrigger } from '../ui/drawer';
import { Button } from '../ui/button';
import { AudioRecorder } from '../../gemini/audio-recorder';
import { useModel } from '../../contexts/ModelContext';
import { useWebcam } from '../../hooks/use-webcam';
import { geminiΩVoices, openaiΩVoices } from '../../constants/voices';
import { useScreenCapture } from '../../hooks/use-screen-capture';
import VideoFeed from '../VideoFeed';
import HiddenVideoContext from './HiddenVIdeoContext';
import { cn } from '../../utils/cn';

interface IconButtonProps {
  isOn: boolean;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
}

const IconButton = memo(
  ({ isOn, onClick, disabled = false, children }: IconButtonProps) => {
    return (
      <Button
        disabled={disabled}
        className={cn(
          'relative aspect-square p-4 h-full col-span-2',
          'bg-black rounded-lg',
          'transition-all duration-200',
          isOn
            ? [
                'before:absolute before:inset-0',
                'before:rounded-lg before:p-[1px]',
                'before:bg-gradient-to-r before:from-[#FBE7FF] before:to-[#7160E9]',
                'before:content-[""]',
                'hover:before:opacity-80',
              ]
            : ['border border-white/15']
        )}
        onClick={onClick}
      >
        <span
          className={cn(
            'absolute inset-[1px] rounded-[7px] bg-black z-0 flex justify-center items-center'
          )}
        >
          {children}
        </span>
      </Button>
    );
  }
);

interface ModelOptionsProps {
  voices: string[];
  model: string;
  voice: string;
  changeVoice: (voice: string) => void;
  handleModelSwitch: (model: 'openai' | 'gemini') => void;
}

const ModelOptionsDrawer = React.memo(
  ({
    voices,
    model,
    voice,
    changeVoice,
    handleModelSwitch,
  }: ModelOptionsProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <Drawer open={isOpen} onOpenChange={setIsOpen}>
        <DrawerTrigger asChild>
          <Button
            onClick={() => setIsOpen((prev) => !prev)}
            className={`border px-2 rounded-lg border-white/15 hover:border-white/70 aspect-square col-span-1 flex items-center justify-center ${
              isOpen ? 'border-white/70' : 'border-white/15'
            } z-[100] h-full aspect-square rounded-xl col-span-2`}
          >
            <HeadCircuit size={28} />
          </Button>
        </DrawerTrigger>
        <DrawerContent className="fixed inset-x-0 border-none bg-transparent bottom-[4rem]">
          <div className="max-w-md px-4 pb-8">
            <div className="space-y-3 mb-3 border border-white/10 bg-white/5 backdrop-blur-md rounded-2xl mx-3 p-3">
              <h2 className="font-medium text-white mb-1 text-center text-sm text-white/60">
                Muse Voices
              </h2>
              {voices.map((voiceOption) => (
                <Button
                  key={voiceOption}
                  onClick={() => changeVoice(voiceOption)}
                  className={`w-full h-auto py-3 rounded-lg border text-white transition-all text-center text-sm bg-black/30
                  ${
                    voice === voiceOption
                      ? 'border-white/70'
                      : 'border-white/15'
                  }`}
                >
                  {voiceOption}
                </Button>
              ))}
            </div>

            <div className="flex flex-col items-center gap-3 mb-8 border border-white/10 bg-white/5 backdrop-blur-md rounded-2xl mx-3 p-3">
              <h2 className="font-medium text-white mb-1 text-center text-sm text-white/60">
                Muse Modality
              </h2>
              <div className="flex gap-3 items-center w-full">
                <Button
                  onClick={() => handleModelSwitch('openai')}
                  className={`w-full h-auto py-3 rounded-lg border text-white transition-all text-center text-sm bg-black/30
                  ${
                    model === 'openai' ? 'border-white/70' : 'border-white/15'
                  }`}
                >
                  Voice
                </Button>
                <Button
                  onClick={() => handleModelSwitch('gemini')}
                  className={`w-full h-auto py-3 rounded-lg border text-white transition-all text-center text-sm bg-black/30
                  ${
                    model === 'gemini' ? 'border-white/70' : 'border-white/15'
                  }`}
                >
                  + Video
                </Button>
              </div>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    );
  }
);

export default function PhoneControlTray({
  isConnected,
  handleDisconnect,
  handleConnect,
  client,
}: {
  isConnected: boolean;
  handleDisconnect: () => void;
  handleConnect: () => Promise<void>;
  client: any
}) {
  const { geminiVoice, openAiVoice } = useModel();
  const [audioRecorder] = useState(() => new AudioRecorder());
  const [muted, setMuted] = useState(false);
  const {
    model,
    setModel,
    setOpenAiVoice,
    setGeminiVoice,
  } = useModel();

  const {
    isStreaming: isWebcamStreaming,
    start: startWebcam,
    stop: stopWebcam,
    stream: webcamStream,
  } = useWebcam();

  const {
    isStreaming: isScreenCapturing,
    start: startScreenCapture,
    stop: stopScreenCapture,
    stream: screenStream,
  } = useScreenCapture();

  const currentVoice = model === 'openai' ? openAiVoice : geminiVoice;
  
  const voices = model === 'openai' ? openaiΩVoices : geminiΩVoices;
  
  const disconnect = () => {
    stopScreenCapture();
    stopWebcam();
    handleDisconnect();
  }

  const handleModelSwitch = (newModel: 'openai' | 'gemini') => {
    if (isConnected) {
      handleDisconnect();
    }
    setModel(newModel);
  };

  const handleVoiceChange = (voice: string) => {
    if (model === 'openai') {
      setOpenAiVoice(voice);
    } else {
      setGeminiVoice(voice);
    }
  };

  const handleScreenShare = async () => {
    if (model === 'openai') return;
    if (isScreenCapturing) {
      stopScreenCapture();
    } else {
      await startScreenCapture();
    }
  };

  const handleCameraShare = async () => {
    if (model === 'openai') return;
    if (isWebcamStreaming) {
      stopWebcam();
    } else {
      await startWebcam();
    }
  };

  const baseVideoClasses =
    'fixed aspect-[9/16] bg-black rounded-lg overflow-hidden';

  const webcamClasses = `${baseVideoClasses} z-[130] ${
    isScreenCapturing
      ? 'bottom-[8rem] right-[1.25rem] w-14'
      : 'bottom-[8rem] right-[1.25rem] w-28'
  }`;

  const screenClasses = `${baseVideoClasses} z-[120] ${
    isWebcamStreaming
      ? 'bottom-[9rem] right-[2.25rem] w-28'
      : 'bottom-[8rem] right-[1.25rem] w-28'
  }`;

  useEffect(() => {
    if (model === 'gemini') {
      const onData = (base64: string) => {
        client.sendRealtimeInput([
          {
            mimeType: 'audio/pcm;rate=16000',
            data: base64,
          },
        ]);
      };
      if (isConnected && !muted && audioRecorder) {
        audioRecorder.on('data', onData).start();
      } else {
        audioRecorder.stop();
      }
      return () => {
        audioRecorder.off('data', onData);
      };
    }
  }, [isConnected, client, muted, audioRecorder, model]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (
        (event.key === 'm' || event.key === 'M') &&
        !(event.target instanceof HTMLInputElement) &&
        !(event.target instanceof HTMLTextAreaElement)
      ) {
        setMuted((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, []);

  return (
    <>
      {webcamStream && (
        <VideoFeed stream={webcamStream} className={webcamClasses} />
      )}
      {screenStream && (
        <VideoFeed stream={screenStream} className={screenClasses} />
      )}

      {model === 'gemini' && (
        <HiddenVideoContext
          client={client}
          isConnected={isConnected}
          webcamStream={webcamStream}
          screenStream={screenStream}
        />
      )}

      <div className="z-[140] w-11/12 p-2 mx-auto border border-white/10 rounded-2xl bg-black/30 mb-10 grid grid-cols-11 gap-2 fixed bottom-0 left-[50%] translate-x-[-50%]">
        {isConnected ? (
          <>
            <ModelOptionsDrawer
              voices={voices}
              model={model}
              voice={currentVoice}
              changeVoice={handleVoiceChange}
              handleModelSwitch={handleModelSwitch}
            />
            <IconButton
              disabled={model === 'openai'}
              isOn={isWebcamStreaming}
              onClick={handleCameraShare}
            >
              <VideoCamera size={28} />
            </IconButton>
            <Button
              onClick={disconnect}
              className="rounded-full font-bold group-hover:scale-[1.01] transition-all ease-in-out duration-200 col-span-3 h-full bg-disconnect text-white"
            >
              <PhoneDisconnect
                weight="fill"
                size={36}
                className="text-white w-10"
              />
            </Button>
            <IconButton
              disabled={model === 'openai'}
              isOn={isScreenCapturing}
              onClick={handleScreenShare}
            >
              <MonitorArrowUp size={28} />
            </IconButton>
            <Button
              onClick={() => setMuted(!muted)}
              className={`border flex items-center justify-center h-full aspect-square rounded-xl col-span-2 ${
                muted
                  ? 'border-disconnect/50 text-disconnect'
                  : 'border-white/15 text-white'
              }`}
            >
              {muted ? <MicrophoneSlash size={28} /> : <Microphone size={28} />}
            </Button>
          </>
        ) : (
          <>
            <ModelOptionsDrawer
              voices={voices}
              model={model}
              voice={currentVoice}
              changeVoice={handleVoiceChange}
              handleModelSwitch={handleModelSwitch}
            />
            <Button
              onClick={handleConnect}
              className="bg-white text-black rounded-xl font-bold group-hover:scale-[1.01] transition-all ease-in-out duration-200 col-start-4 col-span-5 h-full"
            >
              Talk with
              <br />
              OMEGA Muse
            </Button>
          </>
        )}
      </div>
    </>
  );
}
