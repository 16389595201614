import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select';
import { Button } from '../ui/button';
import { useModel } from '../../contexts/ModelContext';
import {
  geminiΩVoices,
  openaiΩVoices,
  defaultGeminiVoice,
  defaultOpenaiVoice,
} from '../../constants/voices';

const ModelOptions = ({isConnected}: {isConnected: boolean}) => {
  const {
    geminiVoice,
    setGeminiVoice,
    openAiVoice,
    setOpenAiVoice,
    setModel,
    model,
  } = useModel();

  const voices = model === 'openai' ? openaiΩVoices : geminiΩVoices;
  const defaultVoice =
    model === 'openai' ? defaultOpenaiVoice : defaultGeminiVoice;

  const handleChangeVoice = (voice: string) => {
    if (model === 'openai') {
      setOpenAiVoice(voice);
    } else {
      setGeminiVoice(voice);
    }
  };

  const handleChangeModel = (model: 'openai' | 'gemini') => {
    // Toggle voice
    if (model === 'openai') {
      setOpenAiVoice(defaultOpenaiVoice);
    } else {
      setGeminiVoice(defaultGeminiVoice);
    }

    // Toggle model
    setModel(model);
  };

  return (
    <div className="flex items-center gap-3">
      <Select
        value={model === 'openai' ? openAiVoice : geminiVoice}
        defaultValue={defaultVoice}
        onValueChange={(v: string) => handleChangeVoice(v)}
      >
        <SelectTrigger
          disabled={isConnected}
          className="bg-black border border-white/20 cursor-pointer relative min-w-[15ch] rounded-lg focus-visible:border-white/60 focus:outline-none hover:border-white/70 transition-color ease-out duration-200"
        >
          <h2 className="absolute text-xs font-bold text-white/30 -top-4 left-1">
            Muse Voice
          </h2>
          <SelectValue placeholder="Select a voice" className="px-2" />
        </SelectTrigger>
        <SelectContent className="bg-black text-white border-white/15 px-2">
          {voices.map((v, i) => (
            <SelectItem value={v} key={i}>
              {v}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <div className="flex h-10 bg-black p-1 border border-white/20 rounded-lg focus-visible:border-white/60 focus:outline-none hover:border-white/70 transition-color ease-out duration-200 z-10">
        <Button
          onClick={() => handleChangeModel('openai')}
          className={`px-3 h-auto py-1.5 rounded-md transition-colors ${
            model === 'openai' ? 'bg-white/15' : 'hover:bg-white/5'
          }`}
        >
          Voice
        </Button>
        <Button
          onClick={() => handleChangeModel('gemini')}
          className={`px-3 h-auto py-1.5 rounded-md transition-colors ${
            model === 'gemini' ? 'bg-white/15' : 'hover:bg-white/5'
          }`}
        >
          + Video
        </Button>
      </div>
    </div>
  );
};

export default ModelOptions;
