import { create } from 'zustand';

interface ScreenshareState {
  isStreaming: boolean;
  stream: MediaStream | null;
  setStream: (stream: MediaStream | null) => void;
  setIsStreaming: (isStreaming: boolean) => void;
}

export const useScreenshareStore = create<ScreenshareState>((set) => ({
  isStreaming: false,
  stream: null,
  setStream: (stream) => set({ stream }),
  setIsStreaming: (isStreaming) => set({ isStreaming }),
}));
