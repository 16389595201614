import { useState } from "react";
import { Button } from "./ui/button";
import { ItemType } from "../openai-realtime-api-beta/lib/client";

const Transcript = ({ items }: { items: ItemType[] }) => {
  const [transcriptShown, setTranscriptShown] = useState(false);
  return (
    <>
      <Button
        className={`bg-black rounded-lg border cursor-pointer z-10 hover:border-white/70 border-white/15 transition-all ease-in-out duration-200 w-fit md:mt-0 mt-[5rem]`}
        onClick={() => setTranscriptShown((prev) => !prev)}
      >
        {transcriptShown ? 'Hide Transcript' : 'Show Transcript'}
      </Button>
      <div className="content-logs h-full w-[400px] pointer-events-none">
        <div className="content-block conversation !max-h-[70vh]">
          {transcriptShown && (
            <div
              className="content-block-body mx-auto max-w-7xl w-full !pt-5"
              data-conversation-content
            >
              {!items.length && `start a conversation...`}
              {items.map((conversationItem, i) => {
                return (
                  <div
                    className="max-w-[400px] pointer-events-auto conversation-item flex flex-col !gap-0"
                    key={conversationItem.id}
                  >
                    <div className={`speaker ${conversationItem.role || ''}`}>
                      <div>
                        {(conversationItem.role
                          ? conversationItem.role === 'user'
                            ? 'me'
                            : 'Ω'
                          : conversationItem.type
                        ).replaceAll('_', ' ')}
                      </div>
                    </div>
                    <div className="speaker-content">
                      {/* tool response */}
                      {conversationItem.type === 'function_call_output' && (
                        <div>{conversationItem.formatted.output}</div>
                      )}
                      {/* tool call */}
                      {!!conversationItem.formatted.tool && (
                        <div>
                          {conversationItem.formatted.tool.name}(
                          {conversationItem.formatted.tool.arguments})
                        </div>
                      )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'user' && (
                          <div className="text-white/75 my-2">
                            {conversationItem.formatted.transcript ||
                              (conversationItem.formatted.audio?.length
                                ? '(awaiting transcript)'
                                : conversationItem.formatted.text ||
                                  '(item sent)')}
                          </div>
                        )}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'assistant' && (
                          <div className="text-white/75 my-2">
                            {conversationItem.formatted.transcript ||
                              conversationItem.formatted.text ||
                              '(truncated)'}
                          </div>
                        )}
                      {/* {conversationItem.formatted.file && (
                        <audio
                          className="pointer-events-auto"
                          src={conversationItem.formatted.file.url}
                          controls
                        />
                      )} */}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Transcript;