import { useCallback, useEffect, useRef } from 'react';
import { useWebcam } from './use-webcam';
import { useScreenCapture } from './use-screen-capture';
import { useLiveAPIContext } from '../contexts/LiveAPIContext';
import { useGeminiStore } from '../stores/gemini-store';

export function useGeminiLive() {
  const { client, connected, connect, disconnect, volume } =
    useLiveAPIContext();

  const { isStreaming: isWebcamStreaming, stop: stopWebcam } = useWebcam();
  const { isStreaming: isScreenCapturing, stop: stopScreenCapture } =
    useScreenCapture();

  const {
    audioRecorder,
  } = useGeminiStore(store => store);

  // Animation-specific refs
  const splineRef = useRef<any>();
  const prevScaleRef = useRef(1);
  const velocityRef = useRef(0);
  const animationFrameRef = useRef<number>();

  const handleConnect = useCallback(async () => {
    if (splineRef.current) {
      splineRef.current.emitEvent('mouseDown', 'Twin');
    }
    await connect();
  }, [connect]);

  const handleDisconnect = useCallback(async () => {
    if (splineRef.current) {
      splineRef.current.emitEventReverse('mouseDown', 'Twin');
    }
    await disconnect();
    if (stopWebcam) stopWebcam();
    if (stopScreenCapture) stopScreenCapture();
  }, [disconnect, stopWebcam, stopScreenCapture]);

  useEffect(() => {
    const handleClose = () => {
      if (stopWebcam) stopWebcam();
      if (stopScreenCapture) stopScreenCapture();
    };

    client.on('close', handleClose);
    return () => {
      client.off('close', handleClose);
    };
  }, [client, stopWebcam, stopScreenCapture]);

  // Animation effect
  useEffect(() => {
    let isActive = true;


    const animate = () => {
      if (!isActive || !splineRef.current || !connected) return;
      
      const stiffness = 0.1; // lower is smoother
      const damping = 0.3;
      const targetScale = 1 + volume * 2;

      const delta = targetScale - prevScaleRef.current;
      velocityRef.current += delta * stiffness;
      velocityRef.current *= damping;

      const smoothScale = prevScaleRef.current + velocityRef.current;
      prevScaleRef.current = smoothScale;
      splineRef.current.setVariable('TwinScale', smoothScale);
      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      isActive = false;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [volume]);

  const onSplineLoad = useCallback(
    (splineApp: any) => {
      splineRef.current = splineApp;
      if (connected) {
        splineApp.emitEvent('mouseDown', 'Twin');
      }
    },
    [connected]
  );

  return {
    isConnected: connected,
    connect: handleConnect,
    disconnect: handleDisconnect,
    volume,
    isWebcamStreaming,
    isScreenCapturing,
    onSplineLoad,
    client,
    audioRecorder
  };
}
