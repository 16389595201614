import { useEffect, useCallback, useRef } from "react";
import { useWebcamStore } from "../stores/webcam-store";

type UseMediaStreamResult = {
  start: () => Promise<MediaStream>;
  stop: () => void;
  isStreaming: boolean;
  stream: MediaStream | null;
};

export function useWebcam(): UseMediaStreamResult {
  const { stream, setStream, isStreaming, setIsStreaming } = useWebcamStore(store => store);
  const activeTracksRef = useRef<MediaStreamTrack[]>([]);

  useEffect(() => {
    if (stream) {
      activeTracksRef.current = stream.getTracks();
      setIsStreaming(true);
    } else {
      setIsStreaming(false);
    }
  }, [stream]);

  const stop = useCallback(() => {
    console.log("Stopping webcam", activeTracksRef.current);
    activeTracksRef.current.forEach(track => {
      console.log("Stopping track:", track.kind, track.readyState);
      track.stop();
    });
    activeTracksRef.current = [];
    setStream(null);
    setIsStreaming(false);
  }, []);

  const start = async () => {
    console.log("Starting webcam");
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });
    console.log("Got new mediaStream:", mediaStream.id);
    activeTracksRef.current = mediaStream.getTracks();
    setStream(mediaStream);
    return mediaStream;
  };

  useEffect(() => {
    const handleStreamEnded = () => {
      console.log("Stream ended naturally");
      stop();
    };

    if (stream) {
      stream.getTracks().forEach((track) => 
        track.addEventListener("ended", handleStreamEnded)
      );
      return () => {
        stream.getTracks().forEach((track) =>
          track.removeEventListener("ended", handleStreamEnded)
        );
      };
    }
  }, [stream, stop]);

  return {
    start,
    stop,
    isStreaming,
    stream,
  };
}