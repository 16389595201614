import { createContext, useContext, useState, ReactNode } from 'react';
import { defaultGeminiVoice, defaultOpenaiVoice } from '../constants/voices';

type ModelType = 'openai' | 'gemini';

interface ModelContextType {
  model: ModelType;
  setModel: (model: ModelType) => void;
  geminiVoice: string;
  setGeminiVoice: (voice: string) => void;
  openAiVoice: string;
  setOpenAiVoice: (voice: string) => void;
}

const ModelContext = createContext<ModelContextType | undefined>(undefined);

export function ModelProvider({
  initialModel,
  children,
}: {
  initialModel: 'openai' | 'gemini';
  children: ReactNode;
}) {
  const [model, setModel] = useState<ModelType>(initialModel);
  const [geminiVoice, setGeminiVoice] = useState<string>(defaultGeminiVoice);
  const [openAiVoice, setOpenAiVoice] = useState<string>(defaultOpenaiVoice);

  return (
    <ModelContext.Provider
      value={{
        model,
        setModel,
        geminiVoice,
        setGeminiVoice,
        openAiVoice,
        setOpenAiVoice,
      }}
    >
      {children}
    </ModelContext.Provider>
  );
}

export function useModel() {
  const context = useContext(ModelContext);
  if (context === undefined) {
    throw new Error('useModel must be used within a ModelProvider');
  }
  return context;
}
