import { useCallback, useEffect, useRef, useState } from 'react';
import { instructions } from '../utils/conversation_config.js';
import { systemPrompt } from '../constants/voices';
import { useOpenAiTranscriptStore } from '../stores/openai-transcript-store';
import { useOpenAIStore } from '../stores/openai-store';
import { WavStreamPlayer } from '../lib/wavtools/index.js';
import { RealtimeClient } from '../openai-realtime-api-beta';

const RELAY_SERVER_URL: string =
  process.env.REACT_APP_RELAY_SERVER_URL || '';
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

export function useOpenAiRealtime() {
  const { items, setItems } = useOpenAiTranscriptStore((store) => store);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const {
    muted,
    setMuted,
    isConnected,
    setIsConnected,
    isRecording,
    setIsRecording,
    wavRecorder,
  } = useOpenAIStore((store) => store);

  const splineRef = useRef<any>();
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      RELAY_SERVER_URL
        ? { url: RELAY_SERVER_URL + '/voice_only' }
        : { dangerouslyAllowAPIKeyInBrowser: false }
    )
  );

  const connect = useCallback(
    async (chosenVoice: string) => {
      const client = clientRef.current;
      client.updateSession({
        turn_detection: { type: 'server_vad', silence_duration_ms: 500 },
        instructions: systemPrompt,
        // @ts-ignore
        voice: chosenVoice,
      });

      setIsConnected(true);
      setRealtimeEvents([]);
      setItems(client.conversation.getItems());

      if (splineRef.current) {
        splineRef.current.emitEvent('mouseDown', 'Twin');
      }

      await wavRecorder.begin();
      await wavStreamPlayerRef.current.connect();
      await client.connect();

      client.sendUserMessageContent([{ type: 'input_text', text: 'Hello!' }]);

      if (!muted && client.getTurnDetectionType() === 'server_vad') {
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
      }
    },
    [muted, wavRecorder, setIsConnected, setRealtimeEvents, setItems]
  );

  const disconnect = useCallback(async () => {
    const client = clientRef.current;
    setIsConnected(false);

    if (splineRef.current) {
      splineRef.current.emitEventReverse('mouseDown', 'Twin');
    }

    setRealtimeEvents([]);
    setItems([]);

    client.disconnect();
    await wavRecorder.end();
    await wavStreamPlayerRef.current.interrupt();
  }, [wavRecorder, setIsConnected, setRealtimeEvents, setItems]);

  const startRecording = useCallback(async () => {
    const client = clientRef.current;
    if (muted) return;

    setIsRecording(true);
    const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }

    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  }, [muted, wavRecorder, setIsRecording]);

  const stopRecording = useCallback(async () => {
    const client = clientRef.current;
    setIsRecording(false);
    await wavRecorder.pause();
    client.createResponse();
  }, [wavRecorder, setIsRecording]);

  useEffect(() => {
    const client = clientRef.current;
    client.updateSession({ instructions });
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    const handleRealtimeEvent = (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((prevEvents) => {
        const lastEvent = prevEvents[prevEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          lastEvent.count = (lastEvent.count || 0) + 1;
          return prevEvents.slice(0, -1).concat(lastEvent);
        }
        return prevEvents.concat(realtimeEvent);
      });
    };

    const handleConversationUpdate = async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayerRef.current.add16BitPCM(delta.audio, item.id);
      }

      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    };

    const handleConversationInterruption = async () => {
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    };

    client.on('realtime.event', handleRealtimeEvent);
    client.on('error', console.error);
    client.on('conversation.interrupted', handleConversationInterruption);
    client.on('conversation.updated', handleConversationUpdate);

    setItems(client.conversation.getItems());

    return () => {
      client.off('realtime.event', handleRealtimeEvent);
      client.off('error', console.error);
      client.off('conversation.interrupted', handleConversationInterruption);
      client.off('conversation.updated', handleConversationUpdate);
      client.reset();
    };
  }, []);

  useEffect(() => {
    const client = clientRef.current;
    if (muted && wavRecorder.getStatus() === 'recording') {
      wavRecorder.pause();
    } else if (
      !muted &&
      isConnected &&
      client.getTurnDetectionType() === 'server_vad'
    ) {
      wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, [muted, isConnected, wavRecorder]);

  useEffect(() => {
    let isLoaded = true;

    const render = () => {
      if (isLoaded) {
        const result = wavStreamPlayerRef.current.analyser
          ? wavStreamPlayerRef.current.getFrequencies('voice')
          : { values: new Float32Array([0]) };

        const scaleValue = Math.max(
          1,
          1 + (Math.max(...result.values) / 255) * 50
        );

        if (splineRef.current) {
          splineRef.current.setVariable('TwinScale', scaleValue);
        }

        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  const onSplineLoad = (splineApp: any) => {
    splineRef.current = splineApp;
  };

  return {
    client: clientRef.current,
    muted,
    setMuted,
    items,
    realtimeEvents,
    isConnected,
    isRecording,
    connect,
    disconnect,
    startRecording,
    stopRecording,
    onSplineLoad,
  };
}
