import { create } from 'zustand';
import { ItemType } from '../openai-realtime-api-beta/lib/client';

interface TranscriptState {
  items: ItemType[];
  setItems: (items: ItemType[]) => void;
}

export const useOpenAiTranscriptStore = create<TranscriptState>((set) => ({
  items: [],
  setItems: (items) => set({ items }),
}));
